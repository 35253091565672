import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Socket } from "socket.io-client";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { SecondaryLink, Button } from "../components/Button";
import { Input, Select, Label } from "../components/Input";
import { Window, WindowTitle, WindowFooter } from "../components/Window";

const Form = styled.form`
  display: flex;
  flex-flow: column;
`;
const Circle = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border-radius: 50%;
  padding: 0 5px;
  background-color: #2196f3cc;
  color: #fff;
  font-size: 14px;
`;
const PrivateContainer = styled.div`
  margin-top: 40px;
`;

const maxCards = 40;
const minCards = 5;
const maxPlayers = 5;
const minPlayers = 2;

interface Props {
  socket: Socket;
  setRoomId: (id: string) => void;
}

export const CreateRoom = ({ socket, setRoomId }: Props) => {
  const [name, setName] = useState(localStorage.getItem("name") || "");
  const [capacity, setCapacity] = useState<number | undefined>(5);
  const [cards, setCards] = useState<number | undefined>(33);
  const [level, setLevel] = useState(0);
  const [isPrivate, setIsPrivate] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    socket.on("create-room-success", (roomId: string) => {
      setRoomId(roomId);
      navigate(`/waiting-room`, { replace: true });
    });

    return () => {
      socket.removeAllListeners("create-room-success");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setNumber = (val: string, setter: (v?: number) => void) => {
    const value = parseInt(val, 10);
    if (val === "" || !isNaN(value)) {
      setter(value || undefined);
    }
  };

  return (
    <Window>
      <WindowTitle>{t("createGameFormHeader")}</WindowTitle>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          localStorage.setItem("name", name);
          cards && localStorage.setItem("numOfCards", cards.toString());
          socket.emit("create-room", {
            name,
            capacity: capacity || 5,
            cards: cards || 33,
            level,
            isPrivate,
          });
        }}
      >
        <Input
          type="text"
          placeholder={t("nicknamePlaceholder")}
          value={name}
          onChange={(e) => setName(e.target.value.trim())}
          required
        />
        <Label>
          <Input
            type="number"
            max={maxPlayers}
            min={minPlayers}
            value={capacity}
            onChange={(e) => setNumber(e.target.value, setCapacity)}
            required
          />
          <span>{t("createGamePlayersLabel")}</span>
        </Label>
        <Label>
          <Input
            type="number"
            max={maxCards}
            min={minCards}
            value={cards}
            onChange={(e) => setNumber(e.target.value, setCards)}
            required
          />
          <span>{t("createGameCardsLabel")}</span>
        </Label>
        <Label>
          <Select required onChange={(e) => setLevel(parseInt(e.target.value) || 0)} value={level}>
            <option value="0">{t("levelHardLabel")}</option>
            <option value="1">{t("levelEasyLabel")}</option>
          </Select>
          {t("createGameLevelLabel")}
        </Label>
        <PrivateContainer>
          <Label>
            <Input
              type="checkbox"
              value={isPrivate.toString()}
              onChange={() => setIsPrivate(!isPrivate)}
            />
            {t("createGamePrivateGameLabel")}
            <Circle title={t("createGamePrivateGameHint")}>?</Circle>
          </Label>
        </PrivateContainer>
        <WindowFooter>
          <SecondaryLink to="/">{t("backLink")}</SecondaryLink>
          <Button type="submit">{t("createGameButton")}</Button>
        </WindowFooter>
      </Form>
    </Window>
  );
};
