import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

const style = css`
  text-transform: uppercase;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  transition: all 0.2s;
  color: #ffffff;
  background-color: #2196f3;
  display: inline-block;
  font-weight: normal;
  font-family: "Lora", serif;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 16px;
  font-size: 13px;
  line-height: 1.846;
  border-radius: 3px;
  user-select: none;
  text-decoration: none;
`;

export const Button = styled.button`
  ${style}
`;

export const ButtonSecondary = styled.button`
  ${style}
  background-color: #ff5757;
`;

export const SecondaryLink = styled(Link)`
  color: #ff5757;
`;

export const PrimaryLink = styled(Link)`
  ${style}
`;
