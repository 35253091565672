import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Socket } from "socket.io-client";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

import { Window, WindowTitle } from "../components/Window";
import { Room } from "../model/room";
import { PrimaryLink } from "../components/Button";

const contentStyle = css`
  padding: 5px;

  :first-child {
    text-align: left;
  }
  :last-child {
    text-align: right;
  }
`;
const Td = styled.td`
  ${contentStyle}
  overflow-wrap: anywhere;
`;
const Th = styled.th`
  ${contentStyle}
`;
const Table = styled.table`
  width: 100%;
`;
const TextBox = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
  width: 80%;
  max-width: 900px;
  padding: 10px 20px;
  border-radius: 10px;
  text-align: justify;
  margin: 10px auto;
`;
const Contact = styled.p`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-flow: column;
  margin-top: 50px;
`;
const LanguageContainer = styled.div`
  font-size: 14px;
  align-self: flex-end;
`;
const LanguageText = styled.span`
  margin-right: 10px;
`;
const Button = styled.button`
  margin: 0 5px;
  color: currentColor;
  text-decoration: underline;
  cursor: pointer;
  pointer-events: initial;
  background: transparent;
  border: none;

  &:disabled {
    text-decoration: none;
    cursor: default;
    pointer-events: none;
  }
`;

interface Props {
  socket: Socket;
}

interface OpenRoom extends Omit<Room, "players"> {
  players: number;
}

interface RoomDetailsProps {
  room: OpenRoom;
  roomLevelTranslation: string;
}

const getIsIosWebView = () => {
  // @ts-ignore
  const standalone = "standalone" in window.navigator && window.navigator?.standalone;
  const userAgent = window.navigator.userAgent.toLowerCase();
  const safari = /safari/.test(userAgent);
  const ios = /iphone|ipod|ipad/.test(userAgent);

  if (ios) {
    if ((standalone && !safari) || (!standalone && !safari)) {
      return true;
    }
  }

  return false;
};

const getIsAndroidWebView = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();

  return userAgent.includes("wv");
};

export const RoomList = ({ socket }: Props) => {
  const [rooms, setRooms] = useState<OpenRoom[]>([]);
  const { t, i18n } = useTranslation();
  const isWebView = useMemo(() => getIsIosWebView() || getIsAndroidWebView(), []);

  useEffect(() => {
    socket.emit("room-list-page");
    socket.on("rooms", (r) => setRooms(r));

    return () => {
      socket.removeAllListeners("rooms");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isActive = (lng: string) => i18n.resolvedLanguage === lng;

  return (
    <>
      {window !== window.top && (
        <TextBox>
          <h1>Memory</h1>
          <a href="http://memoryonline.ovh">Memory Online</a>
        </TextBox>
      )}

      <Window gap="20px">
        <LanguageContainer>
          <LanguageText>{t("gameListSelectLanguage")}:</LanguageText>
          <Button onClick={() => i18n.changeLanguage("en")} disabled={isActive("en")}>
            English
          </Button>
          <Button onClick={() => i18n.changeLanguage("pl")} disabled={isActive("pl")}>
            Polski
          </Button>
        </LanguageContainer>
        <WindowTitle>
          <PrimaryLink to="create">{t("gameListNewGameButton")}</PrimaryLink>
        </WindowTitle>
        {!!rooms.length && (
          <Table>
            <thead>
              <tr>
                <Th>{t("gameListLevelLabel")}</Th>
                <Th>{t("gameListRoomNameLabel")}</Th>
                <Th>{t("gameListPlayersLabel")}</Th>
              </tr>
            </thead>
            <tbody>
              {rooms.map((room) => (
                <RoomDetails
                  key={room.id}
                  room={room}
                  roomLevelTranslation={
                    room.level === 0 ? t("levelHardLabel") : t("levelEasyLabel")
                  }
                />
              ))}
            </tbody>
          </Table>
        )}
        {!rooms.length && <span>{t("gameListNoGamesPlaceholder")}</span>}
      </Window>
      {!isWebView && (
        <TextBox>
          <h1>{t("descriptionHeader")}</h1>
          <br />
          <h2>{t("descriptionSection1Header")}</h2>
          <p>{t("descriptionSection1Paragraph1")}</p>
          <p>{t("descriptionSection1Paragraph2")}</p>
          <p>{t("descriptionSection1Paragraph3")}</p>
          <br />
          <h2>{t("descriptionSection2Header")}</h2>
          <p>{t("descriptionSection2Paragraph1")}</p>
          <p>{t("descriptionSection2Paragraph2")}</p>
          <p>{t("descriptionSection2Paragraph3")}</p>
        </TextBox>
      )}
      <Contact>
        <a href="mailto:hello@memoryonline.ovh">{t("contactLink")}</a>
      </Contact>
    </>
  );
};

const RoomDetails = ({ room, roomLevelTranslation }: RoomDetailsProps) => {
  return (
    <tr>
      <Td>{roomLevelTranslation}</Td>
      <Td>
        <Link to={`/join/${room.id}`}>{room.name}</Link>
      </Td>
      <Td>{room.players}</Td>
    </tr>
  );
};

export default RoomList;
