import styled from "styled-components";

export const Window = styled.div<{ gap?: string }>`
  width: 90%;
  max-width: 400px;
  min-height: 500px;
  margin: 50px auto;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgb(89 61 30);
  overflow: auto;
  padding: 20px 10px;
  transition: 1s;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: ${(props) => props.gap || "40px"};
`;

export const WindowTitle = styled.p`
  text-align: center;
`;

export const WindowFooter = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`;
