import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Socket } from "socket.io-client";
import { useTranslation } from "react-i18next";

import { Window, WindowTitle, WindowFooter } from "../components/Window";
import { Button, SecondaryLink } from "../components/Button";
import { Input } from "../components/Input";

interface Props {
  socket: Socket;
  setRoomId: (id: string) => void;
}

export const JoinToRoom = ({ socket, setRoomId }: Props) => {
  const [name, setName] = useState(localStorage.getItem("name") || "");
  const navigate = useNavigate();
  const { roomId = "" } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    socket.on("joined-success", () => {
      setRoomId(roomId);
      navigate("/waiting-room", { replace: true });
    });

    return () => {
      socket.removeAllListeners("joined-success");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Window>
      <WindowTitle>{t("joinPageFormHeader")}</WindowTitle>

      <Input
        type="text"
        placeholder={t("nicknamePlaceholder")}
        defaultValue={name}
        maxLength={20}
        onChange={(e) => setName(e.target.value.trim())}
        required
      />

      <WindowFooter>
        <SecondaryLink to="/">{t("backLink")}</SecondaryLink>
        <Button
          disabled={!name || !roomId}
          onClick={() => {
            localStorage.setItem("name", name);
            socket.emit("join", { room: roomId, player: name });
          }}
        >
          {t("joinPageJoinButton")}
        </Button>
      </WindowFooter>
    </Window>
  );
};

export default JoinToRoom;
