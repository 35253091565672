import { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Socket } from "socket.io-client";
import { useTranslation } from "react-i18next";

import { CreateRoom } from "./containers/CreateRoom";
import { JoinToRoom } from "./containers/JoinToRoom";
import { GameOver } from "./containers/GameOver";
import { RoomList } from "./containers/RoomList";
import { WaitingRoom } from "./containers/WaitingRoom";
import { MemoryGame } from "./containers/MemoryGame";

import "react-toastify/dist/ReactToastify.css";

interface Props {
  socket: Socket;
}

const App = ({ socket }: Props) => {
  const [roomId, setRoomId] = useState(""); // if set means user has joined to room - flag isInRoom
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    socket.on("errors", (errors: string[]) =>
      errors.forEach((err) =>
        toast.error(t(err), {
          position: toast.POSITION.TOP_RIGHT,
          theme: "dark",
        })
      )
    );

    socket.on("disconnect", () => navigate("/"));

    return () => {
      socket.removeAllListeners("errors");
      socket.removeAllListeners("disconnect");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<RoomList socket={socket} />} />
        <Route path="/create" element={<CreateRoom socket={socket} setRoomId={setRoomId} />} />
        <Route
          path="/join/:roomId"
          element={<JoinToRoom socket={socket} setRoomId={setRoomId} />}
        />
        <Route
          path="/waiting-room"
          element={
            <WaitingRoom
              socket={socket}
              clearState={() => {
                setRoomId("");
              }}
              roomId={roomId}
            />
          }
        />
        <Route path="/game" element={<MemoryGame socket={socket} roomId={roomId} />} />
        <Route path="/finish" element={<GameOver />} />
      </Routes>
      <ToastContainer autoClose={3000} />
    </>
  );
};

export default App;
