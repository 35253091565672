import styled from "styled-components";

export const Input = styled.input`
  padding: 10px;
  border: 1px solid #2196f3;
  margin: 4px 0;
  color: rgb(63, 41, 13);
  background-color: rgba(255, 255, 255, 0.4);
  font-weight: bold;
  font-size: 16px;
  border-radius: 0;
`;

export const Select = styled.select`
  padding: 10px;
  border: 1px solid #2196f3;
  margin: 4px 0;
  color: rgb(63, 41, 13);
  background-color: rgba(255, 255, 255, 0.4);
  font-weight: bold;
  font-size: 16px;
`;

export const Label = styled.label`
  display: flex;
  gap: 10px;
  align-items: center;
`;
