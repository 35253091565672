import { useMemo, memo, useState, useEffect } from "react";
import styled, { css } from "styled-components";

import frontcardTexture from "../assets/img/frontcard_texture.jpg";

interface CardProps {
  size: number;
  setActive: () => void;
  isActive: boolean;
  isHidden: boolean;
  isMyTurn: boolean;
  hash: string;
}

interface CardButtonProps {
  opacity: number;
  cursor: string;
  visibility: string;
  zIndex: number;
  transform: string;
  width: string;
  height: string;
}

export const Board = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-content: space-around;
  height: fill-available;
  overflow: hidden;
`;

const backFronCommonStyle = css`
  backface-visibility: hidden;
  transition: 0.5s;
  transform-style: preserve-3d;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 5px;
  box-shadow: 2px 2px 10px rgb(76, 76, 76);
  width: 100%;
  height: 100%;
`;

const CardButton = styled.button.attrs((props: CardButtonProps) => ({
  style: {
    opacity: props.opacity,
    cursor: props.cursor,
    visibility: props.visibility,
    zIndex: props.zIndex,
    transform: props.transform,
    width: props.width,
    height: props.height,
  },
}))`
  transition: 0.5s;
  transform-style: preserve-3d;
  position: relative;
  box-sizing: border-box;
  border: none;
  border-radius: 5px;
  background: transparent;
  margin: 0;
  padding: 0;
`;

const FrontCard = styled.div<{ isActive: boolean }>`
  ${backFronCommonStyle}
  transform: ${(props) => (props.isActive ? "rotateY(180deg)" : "rotateY(0deg)")};
  background-image: url(${frontcardTexture});
  background-size: contain;
`;

const BackCard = styled.div<{ isActive: boolean }>`
  ${backFronCommonStyle}
  transform: ${(props) => (props.isActive ? "rotateY(0deg)" : "rotateY(180deg)")};

  source,
  img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }
`;

const CardComponent = ({ size, setActive, isActive, isHidden, isMyTurn, hash }: CardProps) => {
  const [isClicked, setIsClicked] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const sign = useMemo(() => (Math.random() * 2 < 1 ? "-" : ""), []);
  const degree = useMemo(() => Math.random() * 40, []);

  useEffect(() => {
    if (isClicked) {
      // BUG: match cards, when it disappear very fast click on the same card. Won't be visible
      // but user may uncover only one card because this one is counted as active but in reality
      // is hidden
      setTimeout(() => setIsClicked(false), 2000);
    }
  }, [isClicked]);

  useEffect(() => {
    // This logic is to prevent case when a card is flippering back (isActive === false) and the
    // image is disappearing before the card is covered. The time can't be longer because in next
    // turn other user can click the same card and image might disapear too early.
    if (isActive) {
      setIsVisible(true);
    } else if (isVisible) {
      setTimeout(() => setIsVisible(false), 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  return (
    <CardButton
      opacity={isHidden ? 0 : 1}
      cursor={isActive || isHidden ? "default" : "grab"}
      visibility={!isActive && isHidden ? "hidden" : "visible"}
      zIndex={isActive ? 2 : 1}
      transform={`rotate(${sign}${degree}deg)`}
      width={`${size}px`}
      height={`${size}px`}
      onClick={() => {
        if (isMyTurn && !isClicked) {
          setIsClicked(true);
          setActive();
        }
      }}
      disabled={isActive || isHidden}
    >
      <FrontCard isActive={isActive} />
      <BackCard isActive={isActive}>
        <picture>
          <source srcSet={isVisible ? `/image/${hash}` : ""} />
          <img src={isVisible ? `/image/fallback/${hash}` : ""} alt="" />
        </picture>
      </BackCard>
    </CardButton>
  );
};

export const Card = memo(CardComponent, (prev, next) => {
  if (prev.isMyTurn || next.isMyTurn) {
    return false;
  }

  return prev.isActive === next.isActive;
});
