import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Button } from "../components/Button";
import { Window, WindowFooter } from "../components/Window";

const Table = styled.table`
  margin-top: 40px;
  width: 100%;
  text-align: center;

  thead {
    font-weight: bold;
  }
  td {
    word-break: break-all;
  }
  td:first-child {
    padding: 5px 10px;
    text-align: left;
    width: 70%;
  }
  td:last-child {
    padding: 5px 10px;
    text-align: right;
    width: 30%;
  }
`;
const PlayerName = styled.span<{ isUser?: boolean }>`
  font-weight: ${(props) => (props.isUser ? "bold" : "normal")};
`;

export const GameOver = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const scores = location.state as Record<string, number>;

  return (
    <Window>
      <Table>
        <thead>
          <tr>
            <td className="nick">{t("nicknamePlaceholder")}</td>
            <td>{t("gameOverPageScoreLabel")}</td>
          </tr>
        </thead>
        <tbody>
          {Object.keys(scores)
            .sort((a, b) => scores[b] - scores[a])
            .map((player, i) => (
              <tr key={i}>
                <td>
                  <PlayerName>{player}</PlayerName>
                </td>
                <td>{scores[player]}</td>
              </tr>
            ))}
        </tbody>
      </Table>
      <WindowFooter>
        <Button onClick={() => navigate("/", { replace: true })}>
          {t("gameOverPageGoGameList")}
        </Button>
      </WindowFooter>
    </Window>
  );
};

export default GameOver;
