import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Socket } from "socket.io-client";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Button, ButtonSecondary } from "../components/Button";
import { CopyIcon } from "../components/CopyIcon";
import { Input } from "../components/Input";
import { Window, WindowFooter } from "../components/Window";
import { Card } from "../model/card";
import { Room } from "../model/room";

const Copybox = styled.div`
  display: flex;
  color: #2196f3;
  align-items: center;
`;
const CopyButton = styled(Button)`
  border-radius: 0;
  box-shadow: none;
  border: 1px solid #2196f3;
  padding: 8px 10px;
  line-height: inherit;
  margin: 0;

  &:hover {
    background-color: #1c85d8;
  }

  svg {
    width: 18px;
  }
`;
const Playerbox = styled.div`
  display: flex;
  flex-flow: column;
  padding: 5px;
  font-size: 20px;
  counter-reset: players;
  width: 90%;
  box-sizing: border-box;
  overflow-wrap: anywhere;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  div:before {
    counter-increment: players;
    content: counter(players) ". ";
  }
`;
const CenterP = styled.p`
  text-align: center;
`;

const getIsModeratorValue = (isModerator: boolean) => (isModerator ? 1 : 0);

interface Props {
  socket: Socket;
  clearState: () => void;
  roomId: string;
}
export const WaitingRoom = ({ socket, clearState, roomId }: Props) => {
  const [roomStore, setRoomStore] = useState<Room | null>(null);
  const navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation();

  const id = roomId || params.roomId;

  useEffect(() => {
    if (!id) {
      navigate("/", { replace: true });
      return;
    }

    socket.on("waiting-room", (waitingRoom: Room) => {
      setRoomStore(waitingRoom);
    });
    socket.on("start", (cards: Card[]) => {
      if (cards?.length) {
        navigate("/game", { replace: true, state: cards });
      }
    });

    return () => {
      socket.removeAllListeners("waiting-room");
      socket.removeAllListeners("start");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const players = roomStore?.players || [];
  const isModeratorRole = players.some(
    (p) => p.id === socket.id && p.isModerator
  );
  const gameLink = `${window.location.origin}/join/${id}`;

  // TODO: investigate it
  players.forEach(function (player) {
    // sometimes var player is null, I don't know why...
    // then create fake player's name and send signal
    // then check room and remove broken players
    if (!player) {
      socket.emit("broken-room", id);
    }
  });

  return (
    <Window>
      <Copybox>
        <Input type="text" value={gameLink} readOnly />
        <CopyButton onClick={() => navigator.clipboard.writeText(gameLink)}>
          <CopyIcon />
        </CopyButton>
      </Copybox>
      {!isModeratorRole && <CenterP>{t("waitingPageUserMessage")}</CenterP>}
      {isModeratorRole && players.length > 1 && (
        <Button onClick={() => socket.emit("start-game")}>
          {t("waitingPageStartGameButton")}
        </Button>
      )}
      {isModeratorRole && players.length < 2 && (
        <CenterP className="moderator-msg">
          {t("waitingPageModeratorMessage")}
        </CenterP>
      )}
      <Playerbox>
        {players
          .sort(
            (a, b) =>
              getIsModeratorValue(b.isModerator) -
              getIsModeratorValue(a.isModerator)
          )
          .map((player, index) => (
            <div key={index}>{player.name}</div>
          ))}
      </Playerbox>
      <WindowFooter>
        <ButtonSecondary
          onClick={() => {
            clearState();
            navigate("/", { replace: true });
          }}
        >
          {t("backLink")}
        </ButtonSecondary>
      </WindowFooter>
    </Window>
  );
};

export default WaitingRoom;
