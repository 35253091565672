import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import en from "../translations/en.json";
import pl from "../translations/pl.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: [
        "subdomain",
        "querystring",
        "cookie",
        "localStorage",
        "sessionStorage",
        "navigator",
        "htmlTag",
        "path",
      ],
    },
    fallbackLng: "en",
    debug: false,
    resources: {
      en: {
        translation: en,
      },
      pl: {
        translation: pl,
      },
    },
  });

export default i18n;
