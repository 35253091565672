import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import { io } from "socket.io-client";

import "./utils/i18n";
import App from "./App";

import reportWebVitals from "./reportWebVitals";
import bt from "./assets/img/board_texture.jpg";

const socket = io(window.location.origin, { transports: ["websocket"] });

const GlobalStyle = createGlobalStyle`
  body {
    background-image: url(${bt});
    background-color: #b5926a;
    font-family: 'Lora', serif;
    margin: 0;
    padding: 0;
    color: rgb(63, 41, 13);
  }
`;

const container = document.getElementById("root");
const root = createRoot(container as HTMLElement);
root.render(
  <React.StrictMode>
    <GlobalStyle />
    <BrowserRouter>
      <App socket={socket} />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
