import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";
import { Socket } from "socket.io-client";
import { useTranslation } from "react-i18next";

import { getCardSize } from "../utils/cards";
import { Card, Board } from "../components/Card";

interface Props {
  socket: Socket;
  roomId: string;
}

export const MemoryGame: React.FC<Props> = ({ socket, roomId }) => {
  const [reversedCards, setReversedCards] = useState<string[]>([]);
  const [hiddenCards, setHiddenCards] = useState<string[]>([]);
  const [isMyTurn, setIsMyTurn] = useState(false);
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const cards = location.state as string[];
  const hashes = useMemo(
    () =>
      cards
        .map((value) => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const size = useMemo(() => getCardSize(hashes.length), []);

  useEffect(() => {
    socket.on("reversed-card", ({ hash }) => {
      if (!reversedCards.includes(hash)) {
        setReversedCards((state) => [...state, hash]);
      }
    });

    socket.on("comparison-result", (isMatch) => {
      if (isMatch) {
        setHiddenCards((c) => [...c, ...reversedCards]);
      }

      setTimeout(() => setReversedCards([]), isMatch ? 1 : 500);
    });

    socket.on("make-inactive", () => setIsMyTurn(false));

    socket.on("set-player", () => {
      // delay is intended, let every action from previous turn was completed
      // then allow new user to play with "blank card"
      setTimeout(() => setIsMyTurn(true), 100);

      toast.info(t("gameYourTurnMessage"), {
        position: toast.POSITION.TOP_RIGHT,
        theme: "dark",
        closeButton: true,
        autoClose: 200,
        style: {
          padding: "4px",
          minHeight: "unset",
        },
      });
    });

    socket.on("point", (score) =>
      toast.info(t("gameScoreMessage", { score }), {
        position: toast.POSITION.TOP_RIGHT,
        theme: "dark",
        closeButton: true,
        autoClose: 200,
        style: {
          padding: "4px",
          minHeight: "unset",
        },
      })
    );

    socket.on("finish", (scores: Record<string, number>) =>
      navigate("/finish", { replace: true, state: scores })
    );

    return () =>
      [
        "make-inactive",
        "set-player",
        "finish",
        "reversed-card",
        "comparison-result",
        "point",
      ].forEach((event) => socket.removeAllListeners(event));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reversedCards]);

  useEffect(() => {
    if (!cards?.length || !roomId) {
      navigate("/", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cards?.length, roomId]);

  return (
    <Board>
      {hashes.map((hash: string, i: number) => (
        <Card
          key={i}
          size={size}
          isActive={reversedCards.includes(hash)}
          isHidden={hiddenCards.includes(hash)}
          isMyTurn={isMyTurn}
          hash={hash}
          setActive={() => {
            if (reversedCards.length < 2) {
              socket.emit("reversed", hash);
            }
          }}
        />
      ))}
    </Board>
  );
};
