export const getCardSize = (count: number, emptySpace = 0): number => {
  const size = Math.floor(
    Math.sqrt((window.innerWidth * window.innerHeight - emptySpace) / count)
  );
  const rowSize = Math.floor(window.innerWidth / size);
  const columnSize = Math.floor(window.innerHeight / size);

  if (rowSize * columnSize < count) {
    const rowWidth = size * rowSize;
    const columnWidth = size * columnSize;
    const emptySpaceXAxis = (window.innerWidth - rowWidth) * size * columnSize;
    const emptySpaceYAxis = (window.innerHeight - columnWidth) * size * rowSize;

    return getCardSize(count, emptySpaceXAxis + emptySpaceYAxis);
  }

  return size;
};
